import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
	  <div className="table-wrapper">
	  <div style={{ textAlign: "left"}}>
          <Card style={{"width" : "100%"}}>
            <Card.Header className="text-center" as="h3">Terms and Conditions</Card.Header>
            <Card.Body>
              <ol>
	  	<li><p>Introduction: These terms and conditions (“Terms”) govern your use of the real estate investing analytics website located at cashflowrealestate.ai (the “Website”) and operated by "Your Skyline LLC" referred to within the context of these terms and throughout the website as (“CFRE-AI”) and all content, services, and products available through the Website. By using the Website, you agree to be bound by these Terms. If you do not agree with these Terms, you may not access or use the Website.</p></li>

		<li><p>Content: The content on the Website, including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like (“Content”) and the trademarks, service marks, and logos contained therein (“Marks”), are owned by, licensed to, or collected from public record by CFRE-AI and are subject to copyright and other intellectual property rights under United States and foreign laws. Content is provided to you AS IS for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of CFRE-AI.</p></li>

<li><p>User Conduct: You agree not to engage in any of the following prohibited activities: (i) using the Website for any commercial purposes, other than for personal use unless granted a commercial license by CFRE-AI; (ii) copying, modifying, or creating derivative works based on the Website or its Content; (iii) distributing, publicly displaying, publicly performing, or otherwise using the Website or its Content for any public or commercial purposes other than purposes explictly permitted by the commercial license provided by CFRE-AI ; (iv) using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Website; (v) attempting to interfere with or compromise the system integrity or security of the Website or attempting to decode, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website; (vi) using the Website to transmit, distribute, post, or submit any information concerning any other person or entity, including without limitation, photographs of others without their permission, personal contact information, and credit card numbers; (vii) using the Website to engage in any other conduct that restricts or inhibits any person from using or enjoying the Website, or which, in the judgment of CFRE-AI, exposes CFRE-AI or any of its affiliates, customers, licensors, or service providers to any liability or detriment of any type.</p></li>

<li><p>User Account: In order to access certain features of the Website, you may be required to create an account (“Account”). When creating an Account, you agree to (i) provide accurate, current and complete information about yourself as prompted by the registration form; and (ii) maintain and promptly update your Account information to keep it accurate, current and complete. You are solely responsible for maintaining the confidentiality of your Account password and for any and all activities that occur under your Account. You agree to immediately notify CFRE-AI of any unauthorized use of your Account or password or any other breach of security.</p></li>

<li><p>Proprietary Rights: The Website and its Content, including all intellectual property rights, are and will remain the exclusive property of CFRE-AI and its licensors. Nothing in these Terms transfers any right, title or interest in the Website or its Content to you, and all rights not specifically granted under these Terms are reserved by CFRE-AI.</p></li>

<li><p>Disclaimer of Warranties and Limitation of Liability: The Website and its Content are provided on an “AS IS” and “AS AVAILABLE” basis without any representations, warranties, or conditions of any kind, whether express or implied, including without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. CFRE-AI does not warrant that the Website or its Content will be uninterrupted or error-free, and CFRE-AI will not be liable for any interruptions or errors. In no event will CFRE-AI, its affiliates, licensors, service providers, employees, agents, officers, or directors be liable for damages of any kind, including without limitation, direct, indirect, incidental, punitive, and consequential damages, arising out of your use of the Website or its Content, or your inability to use the Website or its Content, or for any other claim related in any way to your use of the Website or its Content, including without limitation, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content posted, transmitted, or otherwise made available via the Website, even if advised of their possibility. You must verify the accuracy of any analysis, estimate, or calculation on this site, we do not warranty or guarantee the data.</p></li>

<li><p>Indemnification: You agree to indemnify, defend, and hold harmless CFRE-AI, its affiliates, licensors, service providers, employees, agents, officers, and directors from and against all losses, expenses, damages, and costs, including reasonable attorneys’ fees, resulting from any violation of these Terms or any activity related to your Account (including negligent or wrongful conduct), by you or any other person accessing the Website using your Account.</p></li>

<li><p>Termination: CFRE-AI reserves the right, in its sole discretion, to terminate or suspend all or a portion of the Website, or your use of all or a portion of the Website, at any time and with or without notice and with or without cause.</p></li>
		
<li><p>Dispute Resolution: Any dispute arising out of or relating to these Terms or the Website will be resolved through binding arbitration in accordance with the Commercial Arbitration Rules of the American Arbitration Association. Any such dispute shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party.</p></li>

<li><p>Governing Law: These Terms shall be governed by and construed in accordance with the laws of the State of Virginia, without giving effect to any principles of conflicts of law.</p></li>

<li><p>Changes to Terms: CFRE-AI may modify these Terms at any time, and such modifications shall be effective immediately upon posting of the modified Terms. CFRE-AI will make a reasonable effort to notify you of any material changes to these Terms. Your continued use of the Website shall be deemed your conclusive acceptance of the modified Terms.</p></li>

<li><p>Entire Agreement: These Terms constitute the entire agreement between you and CFRE-AI with respect to the use of the Website, and supersedes all prior agreements between the parties regarding the same subject matter.</p></li>

<li><p>Contact Information: If you have any questions or comments regarding these Terms, please contact CFRE-AI at [support@cashflowrealestate.ai].</p></li>  

<li><p>Data Tracking: CFRE-AI uses various tracking technologies, including cookies, Google Analytics, Facebook Pixel, and Conversions API to collect information about how you use the Website, to improve the user experience, and to serve targeted advertising to you. By using the Website, you consent to the collection and use of your data in accordance with CFRE-AI's Privacy Policy.
Please note that customer and user data will be transferred to these service providers in order to provide these services. For more information on how your data is collected and used, please refer to CFRE-AI's Privacy Policy.</p></li>

		</ol>
            </Card.Body>
          </Card>
	</div>
	</div>
  );
};

export default TermsAndConditions;
